.footer {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    bottom: 0%;
    height: auto;
    background-color: whitesmoke;
    border: 1px solid lightgrey;
    text-align: end;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}