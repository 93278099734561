.sign-in {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .form {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        border: 1px solid lightgray;
        padding: 1rem;
        width: 15%;
        align-self: center;
        border-radius: .5rem;

        h2 {
            text-align: center;
        }

        button {
            font-weight: 600;
        }
    }

}

@media (max-width: 768px) {
    .sign-in {
        .form {
            width: 80%;
        }
    }
}