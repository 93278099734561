.images-group {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 24px;
    justify-content: space-around;
}

.def-img {
    width: 20rem;
    height: auto;
}

.delete-btn {
    text-align: center;
    width: 20rem;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 5px 3px;
    border: 1px solid lightgray;
    margin-top: 0 !important;
    cursor: pointer;
}